import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getToken, remove } from '../../src/utils/auth' // get token from cookie
import { start, stop } from "@/utils/signalR";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { title: '首页' }
    },
    // {
    //     path: '/File',
    //     name: 'File',
    //     component: () => import('../views/File.vue'),
    //     meta: { title: '资料' }
    // },
    {
        path: '/category/:cate',
        name: 'category',
        component: () => import('../views/Home.vue'),
        meta: { title: '分类', params: 'cate' }
    },
    {
        path: '/search/:words',
        name: 'search',
        component: () => import('../views/Home.vue'),
        meta: { title: '搜索', params: 'words' }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue'),
        meta: { title: '关于' }
    },
    {
        path: '/friend',
        name: 'friend',
        component: () => import('../views/Friend.vue'),
        meta: { title: '友链' }
    },
    {
        path: '/article/:id',
        name: 'article',
        component: () => import('../views/Articles.vue'),
        meta: { title: '文章', params: 'id' }
    },
    {
        path: "/register",
        name: 'register',
        component: () => import('../views/register.vue'),
        meta: { title: '注册' }
    },
    {
        path: "/Personal",
        name: 'Personal',
        component: () => import('../views/Personal.vue'),
        meta: { title: '个人主页' }
    },
    {
        path: "/acctoken",
        name: 'acctoken',
        component: () => import('../views/acctoken.vue'),
        meta: { title: '登录' }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


/**
 * 
 *  router 钩子函数
 */
router.beforeEach(async (to, from, next) => {
    let title = 'zlBlog'
    if (to.meta.params) {
        title = `${to.meta.title}:${to.params[to.meta.params] || ''} - ${title}`
    } else {
        title = `${to.meta.title} - ${title}`
    }
    document.title = title
    store.dispatch('setLoading', true);
    const hasToken = getToken()//获取当前token 
    if (to.name == "Personal") {
        if (!hasToken) {
            router.push({ name: "home" })
            next();
            return
        }
    }
    if (hasToken) {
        store.dispatch('SET_loginIs', true); //写入是否登录 
        store.dispatch('GetUserBlog').then(res => {
            start(hasToken).then(res1 => {

            });
        })
    }

    else {
        store.dispatch('SET_loginIs', false); //写入是否登录 
    }
    store.dispatch('setLoading', true);
    next();
})
router.afterEach((to, from) => {

    store.dispatch("SET_hidden", true)

    // store.dispatch("SET_lastScrollTop", 0)
    store.dispatch("SET_fixed", true)
    // document.body.scrollTop = 0;

    //store.dispatch('setLoading', false);

    store.dispatch('setLoading', false);
    //最多延迟 关闭 loading

})
export default router
