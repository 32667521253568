import Vue from 'vue'

import Vuex from 'vuex'

import { getTimeInterval } from '../utils/index'

import { getToken, setToken, removeToken } from '../utils/auth'

import { GetStreamModelAsync, WebsiteInfo, GetSocialsModelAsync, GetBlogAboutModel, ClassModelSelect, GetUserBlog, Blogin } from "../api/Resources"; //获取接口对象

Vue.use(Vuex)

// 略:后台获取系统运行时间
const runAt = '1615348371000';

let timer = null;

const state = {
    loading: false,
    signal: "",
    token: getToken(),
    runTimeInterval: '',
    socials: '',
    lastScrollTop: 0,
    Popup: false,
    fixed: false,
    hidden: false,
    websiteInfo: '',
    BlogClass: [],
    UserInfo: "",
    streamModel: "",/** 资源文件 缓存 vuex */
    Islogin: false,/**是否登录 */
    userlogin: null,
    ReplynoticeData: undefined,
    ReplynoticeIsclike: false,/*是否点击 */
    MessageIsclike: false,/*是否点击 */
    MessageData: []

}
const mutations = {

    SET_lastScrollTop: (state, v) => {  //当前滚动区域

        state.lastScrollTop = v;

    },

    SET_fixed: (state, v) => {  //当前坐标

        state.fixed = v;


    },

    SET_hidden: (state, v) => {  //是否显示导航栏
        state.hidden = v;
    },

    SET_ReplynoticeIsclike: (state, v) => {  //是否显示导航栏
        state.ReplynoticeIsclike = v;
    },
    SET_MessageData: (state, v) => {  //是否显示导航栏
        state.MessageData = v;
    },
    SET_MessageIsclike: (state, v) => {  //是否显示导航栏
        state.MessageIsclike = v;
    },
    SET_ReplynoticeData: (state, v) => {  //是否显示导航栏
        state.ReplynoticeData = v;
    },
    SET_loginIs: (state, v) => {  //用户是否登录
        state.Islogin = v;
        if (!v) {
            state.userlogin = null;
        }
    },
    SET_signal: (state, v) => {  //signal
        state.signal = v;
    },



    SET_User: (state, v) => { //用户登录的信息

        state.userlogin = v;
    },

    SET_Popup: (state, v) => { //是否弹出

        state.Popup = v;
    },

    /**
     * 是否显示loding
     */
    SET_LOADING: (state, v) => {
        state.loading = v;
    },

    /**
     *  存放版主信息
     */
    SET_SOCIALS: (state, v) => {
        state.socials = v;
    },
    SET_SITE_INFO: (state, v) => {

        state.websiteInfo = v;
    },

    /**
     * 资源文件 缓存
     */
    SET_STREAM_Modle: (state, v) => {

        state.streamModel = v;
    },


    /**
     * 计算当前运行的总时间
     */
    GET_RUNTIME_INTERVAL: (state) => {

        if (!timer || !state.runTimeInterval) {

            clearInterval(timer)

            timer = setInterval(() => {

                state.runTimeInterval = getTimeInterval(runAt);

            }, 1000);
        }
    }
}
const actions = {

    setLoading: ({ commit }, v) => {

        commit('SET_LOADING', v);
    },

    initComputeTime: ({ commit }) => {

        commit('GET_RUNTIME_INTERVAL');

    },

    SET_Popup: ({ commit }, v) => { // 是否弹出

        commit("SET_Popup", v);
    },

    SET_loginIs: ({ commit }, v) => { //是否登录

        commit("SET_loginIs", v);
    },
    SET_signal: ({ commit }, v) => { //signal
        commit("SET_signal", v);
    },

    SET_lastScrollTop: ({ commit }, v) => { //是否登录

        commit("SET_lastScrollTop", v);
    },

    SET_hidden: ({ commit }, v) => { //是否登录
        commit("SET_hidden", v);
    },
    SET_MessageIsclike: ({ commit }, v) => { //是否登录
        commit("SET_MessageIsclike", v);
    },
    SET_ReplynoticeIsclike: ({ commit }, v) => { //是否登录
        commit("SET_ReplynoticeIsclike", v);
    },
    SET_ReplynoticeData: ({ commit }, v) => { //是否登录
        commit("SET_ReplynoticeData", v);
    },
    SET_MessageData: ({ commit }, v) => { //是否登录
        commit("SET_MessageData", v);
    },
    SET_fixed: ({ commit }, v) => {
        commit("SET_fixed", v);
    },

    /**
     * 获取缓存信息
     */
    getstreamModel: ({ commit, state }) => {

        return new Promise(resolve => {

            GetStreamModelAsync().then(res => {

                resolve(res);

            }).catch(err => {

                resolve({});
            })


        })
    },

    getSiteInfo: ({ commit, state }) => {

        return new Promise(resolve => {

            if (state.UserInfo.rspdata != undefined) {
                resolve(state.UserInfo);
            }

            WebsiteInfo().then(res => {

                state.UserInfo = res;

                resolve(res);

            }).catch(err => {
                resolve({});
            })

        })
    },

    /**
     *  获取页签
     */
    getSocials: ({ commit, state }) => {

        return new Promise((resolve => {

            GetSocialsModelAsync().then(res => {

                resolve(res);

            }).catch(err => {

                resolve([]);
            })

        }))
    },

    Userinfo: ({ commit, state }) => {

        return new Promise((resolve => {

            GetSocialsModelAsync().then(res => {

                resolve(res);

            }).catch(err => {

                resolve([]);
            })

        }))
    },

    /**
     * 存放分类信息
     * @param {*} param0 
     */
    ClassModel: ({ commit, state }) => {

        return new Promise((resolve => {

            if (state.BlogClass.length > 0) {
                resolve(state.BlogClass);
            }
            else {

                ClassModelSelect().then(res => {

                    state.BlogClass = res.rspdata;

                    resolve(res.rspdata);

                }).catch(err => {

                    resolve([]);
                })
            }

        }))

    },
    /**
     * 前台关于页面
     */
    GetBlogAboutModel: ({ commit, state }) => {

        return new Promise((resolve => {

            GetBlogAboutModel().then(res => {

                resolve(res);

            }).catch(err => {

                resolve({});
            })

        }))


    },


    /**
     * 根据token获取用户信息
     * @param {*} param0 
     */

    GetUserBlog: ({ commit, state }) => {

        return new Promise((resolve => {

            GetUserBlog().then(res => {

                commit('SET_User', res.rspdata);

                resolve(true);

            }).catch(err => {

                resolve(false);
            })

        }))
    },

    // user logout
    logout({ commit, state }) {
        return new Promise((resolve => {

            removeToken();

            state.userlogin = null;

            state.Islogin = false;

            resolve(true);

        }))
    },


    /**
     * 登录模板
     * @param {*} param0 
     * @param {*} userInfo 
     */
    Blogin({ commit }, userInfo) {

        return new Promise((resolve, reject) => {
            // const formData = new FormData();
            // formData.append("Username", userInfo.username);
            // formData.append("Password", userInfo.password);
            // formData.append("Key", userInfo.uuid);
            // formData.append("Code", userInfo.code);
            // formData.append("PositionX", userInfo.PositionX);
            // formData.append("PositionY", userInfo.PositionY);
            // formData.append("RemoveIfSuccess",true);
            Blogin(userInfo).then(response => {
                const { rspdata } = response

                setToken(rspdata)
                resolve()

            }).catch(error => {
                reject(error)
            })
        })
    },
}
const getters = {

    loading: state => state.loading,

    runTimeInterval: state => state.runTimeInterval,

    islogin: state => state.Islogin,

    User: state => state.userlogin,

    ReplynoticeIsclike: state => state.ReplynoticeIsclike,

    MessageIsclike: state => state.MessageIsclike,

    MessageData: state => state.MessageData,

    ReplynoticeData: state => state.ReplynoticeData,

    signal: state => state.signal,

    notice: state => state.websiteInfo ? state.websiteInfo.notice : '',

    BlogClass: state => state.BlogClass,

    lastScrollTop: state => state.lastScrollTop,

    fixed: state => state.fixed,

    hidden: state => state.hidden,

    Popup: state => state.Popup

}
export default new Vuex.Store({

    state,

    mutations,

    actions,

    modules: {},

    getters
})
