import request from '@/utils/requesnetcore'



/**
 * 获取资源文件
 * @param {*} data 
 */

export function GetStreamModelAsync() {
    return request({
        url: '/api/Resources/GetStreamModelAsync',
        method: 'get',
    })
}


/**
 * 文章分类全部名称
 */
export function ClassModelSelect() {

    return request({
        url: '/api/Blog/ClassModelSelect',
        method: 'get',
    })
}



/**
 * 获取博主信息
 */
export function WebsiteInfo() {

    return request({
        url: '/api/Login/WebsiteInfo',
        method: 'get',
    })
}


/**
 * 获取页签显示
 */
export function GetSocialsModelAsync() {

    return request({
        url: '/api/Socials/GetSocialsModelAsync',
        method: 'get',
    })
}



/**
 * 前台关于页面
 */
export function GetBlogAboutModel() {

    return request({
        url: '/api/Socials/GetBlogAboutModel',
        method: 'get',
    })
}




/**
 * 添加留言信息
 * @param {*} data 
 */
export function AddBoardModel(data) {

    return request({
        url: '/api/Sysytem/AddBoardModel',
        method: 'post',
        data
    })

}



/**
 * 获取热门文章
 */
export function BlogHotPage() {

    return request({
        url: '/api/Blog/BlogHotPage',
        method: 'get'
    })

}

/**
 * 获取热门文章
 * @param {*} data  分页对象
 */
export function BlogPageList(data) {

    return request({
        url: '/api/Blog/BlogPageList',
        method: 'post',
        data
    })
}



/**
 * 获取单个博客
 * @param {*} BlogId 
 */
export function BlogFirstOrDefaultAsync(BlogId) {

    return request({
        url: '/api/Blog/BlogFirstOrDefault/' + BlogId,
        method: 'get',
    })

}



/**
 *  获取验证码
 */
export function MixVerifyCode() {

    return request({
        url: '/api/Login/MixVerifyCode/',
        method: 'get',
    })

}


/**
 * 发送验证码
 */
export function SendEmail(Email) {

    return request({
        url: '/api/Login/SendEmail/' + Email,
        method: 'get',
    })
}


/**
 * 注册普通用户
 * @param {*} data 
 */
export function Registered(data) {

    return request({
        url: '/api/Login/Registered',
        method: 'Post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
        },
    })
}


/**
 *  获取token用户信息
 */
export function GetUserBlog() {

    return request({
        url: '/api/Login/GetUserBlog',
        method: 'get'
    })
}



/**
 * BLog登录接口
 * 
 * @param {*} data 
 */

export function Blogin(data) {

    return request({
        url: '/api/Login/Blogin',
        method: 'post',
        data
    })
}


/**
 * 修改个人简介
 * @param {*} data 
 */
export function IntroductionUpdateuction(data) {

    return request({
        url: '/api/User/IntroductionUpdateuction',
        method: 'post',
        data
    })
}

/**
 * 修改邮箱
 * @param {*} data 
 */

export function IntroductionUpdateuCode(data) {
    return request({
        url: '/api/User/IntroductionUpdateuCode',
        method: 'post',
        data
    })
}


/**
 * 获取用户浏览历史记录
 * @param {*} data 
 */
export function IntroductionBlog(data) {
    return request({
        url: '/api/User/IntroductionBlog',
        method: 'post',
        data
    })
}


/**
 * 删除用户历史记录
 * @param {*} data 
 */
export function IntroductionBlogDel(data) {

    return request({
        url: '/api/User/IntroductionBlogDel',
        method: 'post',
        data
    })
}


/**
 * 喜欢次数
 * @param {} BlogId 
 * @param {*} Value 
 */
export function BlogFirstOrDefaultLike(data) {
    return request({
        url: '/api/Blog/BlogFirstOrDefaultLike',
        method: 'post',
        data
    })
}

/**
 * 文章收藏次数
 * @param {*} BlogId 
 * @param {*} Value 
 */
export function BlogFirstOrDefaultCollect(data) {

    return request({
        url: '/api/Blog/BlogFirstOrDefaultCollect',
        method: 'post',
        data
    })
}

/**
 * 添加回复信息
 * @param {*} data 
 */
export function AddMessage(data) {
    return request({
        url: '/api/Message/AddMessage',
        method: 'post',
        data
    })
}


/**
 * 根据博客id获取评论信息
 * @param {*} BlogId 
 */
export function PostBlogMessage(data) {
    return request({
        url: '/api/Blog/PostBlogMessage',
        method: 'post',
        data
    })
}


/**
 * 点赞次数
 * @param {*} BlogId 
 */
export function MessageLike(MessageId) {
    return request({
        url: '/api/Message/MessageLike/' + MessageId,
        method: 'get'
    })
}

/**
 * 获取用户信息
 * @param {*} Id 
 */
export function GetUserller(Id) {
    return request({
        url: '/api/User/GetUserller/' + Id,
        method: 'get'
    })
}

/**
 * 在线选择
 * @param {*} data 
 */
export function GetSelectBlogTitle(data) {
    return request({
        url: '/api/Blog/GetSelectBlogTitle',
        method: 'post',
        data
    })
}

/**
 * 获取用户消息管理
 * @param {*} data 
 */
export function GerUserMessage(data) {
    return request({
        url: '/api/Message/GerUserMessage',
        method: 'post',
        data
    })
}

/**
 * 获取消息的点赞用户
 * @param {*} MessageId 
 */
export function GerMessagelike(MessageId) {
    return request({
        url: '/api/Message/GerMessagelike/' + MessageId,
        method: 'get'
    })
}



/**
 * /api/capt/GetInitImage
 * @returns 
 */
export function GetInitImage() {
    return request({
        url: '/api/capt/GetInitImage',
        method: 'get'
    })
}

/**
 * 图片验证 返回结果
 * @returns 
 */
export function ValidateCaptcha(data) {
    return request({
        url: '/api/capt/ValidateCaptcha',
        method: 'post',
        data
    })
}


/**
 * 每日一句
 * @returns 
 */

export function Dailsentence() {
    return request({
        url: '/api/sysytem/dailsentence',
        method: 'Get',
    })

}

/**
 * 滚动条
 * @returns 
 */
export function GetBlogCarousel() {
    return request({
        url: '/api/Blog/BlogCarousel',
        method: 'Get',
    })
}


/**
 * 统计
 * @returns 
 */
export function GetBloggetcount() {
    return request({
        url: '/api/socials/getbloggetcount',
        method: 'Get',
    })
}



/**
 * 获取最新回复
 * @returns 
 */
export function MessageModelAsync() {
    return request({
        url: '/api/message/messagemodel',
        method: 'Get',
    })
}

/**
 * 热门文章
 * @returns 
 */
export function GetBlogarticleDtoAsync() {
    return request({
        url: '/api/blog/blogarticledto',
        method: 'Get',
    })
}

/**
 * Minio文件列表
 * @returns 
 */
export function GetminioliaoqingydtoAsync(params) {
    return request({
        url: '/api/file/minioliaoqingydto',
        method: 'Get',
        params
    })
}

/**
 * Minio文件列表
 * @returns 
 */
export function stringuploadngurl(key) {
    return request({
        url: '/api/file/stringuploadngurl?key=' + key,
        method: 'Post'
    })
}

export function blogfirstdeleit(blog)
{
    return request({
        url: '/api/blog/blogfirstdeleit/' + blog,
        method: 'get'
    })
}