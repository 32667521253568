<template>
  <transition name="slide-fade">
    <div class="back-top">
      <div v-for="(item, index)  in socials" :key="'info2-'+index">
        <a v-if="!item.ispopover" :href="item.href">
          <img style="cursor:pointer;" :src="item.url" :title="item.title" />
        </a>

        <el-popover v-else placement="right" trigger="hover">
          <vue-qr :logoSrc="item.url" :text="item.url" :size="120"></vue-qr>
          <div slot="reference">
            <img style="cursor:pointer;" :src="item.url" :title="item.title" />
          </div>
        </el-popover>
      </div>
      <div v-show="show" @click.stop="getTop">
        <img class="img1" src="@/assets/向上.png" title="返回顶部" />
        <img class="img2" src="@/assets/向上red.png" alt="返回顶部" />
      </div>
    </div>
  </transition>
</template>

<script>
import { GetSocialsModelAsync } from "../api/Resources"; //获取接口对象
import VueQr from "vue-qr";
export default {
  name: "back-top",
  components: {
    VueQr
  },
  data() {
    return {
      show: false,
      socials: []
    };
  },
  methods: {
    scroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 100) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    getTop() {
      let timer = setInterval(() => {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        let speed = Math.ceil(scrollTop / 5);
        document.documentElement.scrollTop = scrollTop - speed;
        if (scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    getSocial() {
      GetSocialsModelAsync()
        .then(res => {
          this.socials = res.rspdata;
        })
        .catch(err => {
          this.socials = [];
        });
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
  },
  created() {
    this.$nextTick(function() {
      this.getSocial();
    });
  }
};
</script>

<style scoped lang="less">
.back-top {
  color: black;
  position: fixed;
  right: 30px;
  bottom: 3rem;
  border-radius: 25px;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.3s;
  text-align: center;
  z-index: 10009;
  cursor: pointer;
  color: #1d1d1d;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  // background-color: #fff;
    background-color:transparent;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  img {
    width: 35px;
    height: 35px;
    margin: 5px;
  }
  .img2 {
    display: none;
  }
  div:hover img.img1 {
    display: none;
  }
  div:hover img.img2 {
    display: unset;
  }
}
@media (max-width: 600px) {
  .back-top {
    display: none;
  }
}
</style>
