<template>
  <div id="app" v-cloak>
    <loading></loading>
    <layout-header></layout-header>
    <layout-body></layout-body>
    <layout-footer></layout-footer>

    <el-dialog
      title="消息通知"
      :show-close="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      custom-class="el-dialog-radius"
      :modal="true"
      :modal-append-to-body="false"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <Like v-if="MessageIsclike" :msgObject="MessageData"></Like>

      <Websortrley  :msgObject="ReplynoticeData"  v-if="ReplynoticeIsclike"></Websortrley>
    </el-dialog>
  </div>
</template>
<script>
import layoutHeader from "@/components/layout/layout-header";
import layoutBody from "@/components/layout/layout-body";
import layoutFooter from "@/components/layout/layout-footer";
import Loading from "@/components/loading";
import Like from "@/components/Like";
import Websortrley from "@/components/Websortrley";
export default {
  name: "app",
  components: {
    layoutHeader,
    layoutBody,
    layoutFooter,
    Loading,
    Like,
    Websortrley,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ReplynoticeIsclike() {
      return this.$store.state.ReplynoticeIsclike;
    },
    MessageIsclike() {
      return this.$store.state.MessageIsclike;
    },
    MessageData() {
      return this.$store.state.MessageData;
    },
    ReplynoticeData() {
      return this.$store.state.ReplynoticeData;
    },
  },
  methods: {
    handleClose() {
      this.$store.dispatch("SET_MessageIsclike", false);
      this.$store.dispatch("SET_MessageData", undefined);
      this.$store.dispatch("SET_ReplynoticeIsclike", false);
      this.$store.dispatch("SET_ReplynoticeData", undefined);
      this.dialogVisible = false;
    },
    preD(e) {
      e.preventDefault();
    },
  },
  watch: {
    // 监听data中弹层状态
    dialogVisible(val) {
      if (val) {
        document.body.style.overflow = "hidden";

        document.addEventListener("touchmove", this.preD, { passive: false }); // 禁止页面滑动
      } else {
        document.body.style.overflow = ""; // 出现滚动条

        document.removeEventListener("touchmove", this.preD, {
          passive: false,
        });
      }
    },
    ReplynoticeIsclike: {
      immediate: true,
      handler: function (newval) {
        if (newval == true) {
          this.dialogVisible = true;
        }
      },
    },
    MessageIsclike: {
      immediate: true,
      handler: function (newval) {
        if (newval == true) {
          if ((this.dialogVisible = false)) {
            this.dialogVisible = true;
          }
        } else {
          if (this.dialogVisible == true) {
            this.dialogVisible = false;
          }
        }
      },
    },
  },
  //===========================下面是解决刷新页面丢失vuex数据
  created() {
    // // 在页面加载时读取sessionStorage
    // if (sessionStorage.getItem("store")) {
    //   console.log("正在从sessionStorage恢复store");
    //   console.log(this.$store.state);
    //   this.$store.replaceState(
    //     Object.assign(
    //       {},
    //       this.$store.state,
    //       JSON.parse(sessionStorage.getItem("store"))
    //     )
    //   );
    // }
    // // 在页面刷新时将store保存到sessionStorage里
    // window.addEventListener("beforeunload", () => {
    //   sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    // });
  },
};
</script>
<style lang="less">
#app {
  font-size: 17px;
  color: #6f6f6f;
}
</style>

<style lang="scss">

.el-dialog__wrapper
{
  z-index: 2500 !important;
}
.el-dialog.el-dialog-radius {
  position: relative;
  margin: 0 auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  width: 50%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
</style>
