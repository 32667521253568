import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/style.less'
import './assets/font/iconfont.css'
import './assets/fonts/iconfont.css'
import ElementUI from 'element-ui'
import 'prismjs/themes/prism.css'
import 'element-ui/lib/theme-chalk/index.css'
import { formatDate, TimeReduction } from '../src/utils/date'
Vue.prototype.$TimeReduction = TimeReduction;
import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.use(ElementUI, { locale })
import moment from 'moment'
Vue.config.productionTip = false
import VueCropper from 'vue-cropper'//注入cropper
// highlight.js代码高亮插件
import Highlight from './utils/highlight'; // from 路径是highlight.js的路径，纯属自定义
Vue.use(Highlight);
Vue.use(VueCropper)
Vue.filter('dateformat', function (dataStr, pattern = 'YYYY-MM-DD ') {
  return moment(+dataStr).format(pattern)
})
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.filter('formatDate', formatDate) //vue 日期字符串转为刚刚、1分钟前、1小时前、1天前、1周前、1月前
 
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
