<template>
  <div id="login">
    <div class="pc-all-dia-tit">
      <p class="pc-all-dia-tit-fu">
        Gitee.com(码云) 是 OSCHINA.NET 推出的代码托管平台,支持 Git 和
        SVN,提供免费的私有仓库托管。目前已有超过 600 万的开发者选择 Gitee。
      </p>
      <div class="pc-all-dia-tit-logo">
     <img src="@/assets/logo_gitee_light.png" alt="" />
      </div>
    </div>

    <!-- <a
      class="register"
      href="
https://gitee.com/oauth/authorize?client_id=c724642fec67294d1caa24ee516eb4d115201c0ec3dcfbe87ee1da4a354e8b4d&redirect_uri=http://localhost:9999/api/Login/redirect&response_type=code&&scope=user_info
"
    >
      <span> 使用 Gitee 帐号登录 </span>-
    </a> -->
  </div>
</template>

<script>
import { MixVerifyCode, GetInitImage, ValidateCaptcha } from "../api/Resources";
import slider from "../components/slider";
export default {
  name: "Login",
  components: { slider },
  data() {
    return {
      codeUrl: "",
      loading: false,
      ImageInitloading: false,
      positionx: 0,
      dataimg: {
        key: "",
        visible: false,
        backgroundimg: "",
        slideimg: "",
        positionx: 0,
        positiony: 0,
        bgheight: 0,
        bgwidth: 274,
        slideheight: 0,
        slidewidth: 0,
      },
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
        PositionX: "",
        PositionY: "",
      },
      loginRules: {
        rememberMe: [
          {
            validator: (rule, value, callback) => {
              if (value !== true) {
                callback(new Error("请确认同意注册协议"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],

        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
        code: [
          { required: true, trigger: "change", message: "验证码不能为空" },
        ],
      },

      loading: false,

      MixVerifyCodelading: false,

      redirect: undefined,
    };
  },

  created() {
    // this.getCode();
  },

  computed: {
    slideImgStyle() {
      var left = `${(this.dataimg.positionx / this.dataimg.bgwidth) * 100}%`;
      var top = `${this.dataimg.positiony}px`;
      return { top: top, left: left };
    },
  },

  methods: {
    change(data) {
      this.loginForm.uuid = this.dataimg.key;
      this.loginForm.PositionX = this.dataimg.positionx;
      this.loginForm.PositionY = this.dataimg.positiony;
      this.$store
        .dispatch("Blogin", this.loginForm)
        .then((res) => {
          this.dataimg.visible = false;
          this.loading = false;
          this.$emit("fatherMethod", true);
        })
        .catch((err) => {
          this.getCode();
          this.loading = false;
        });
    },
    input(data) {
      this.dataimg.positionx = data;
    },

    getCode() {
      this.MixVerifyCodelading = true;

      MixVerifyCode()
        .then((res) => {
          this.codeUrl = res.rspdata.imgstring;

          this.loginForm.uuid = res.rspdata.key;

          this.MixVerifyCodelading = false;
        })
        .catch((error) => {
          this.MixVerifyCodelading = false;
        });
    },

    ImageInit() {
      return new Promise((resolve) => {
        this.ImageInitloading = true;
        GetInitImage()
          .then((res) => {
            this.dataimg.key = res.rspdata.key;
            this.$refs.dcPopover.value3 = 0;
            this.dataimg.backgroundimg = res.rspdata.backgroundimg;
            this.dataimg.slideimg = res.rspdata.slideimg;
            this.dataimg.positionx = res.rspdata.positionx;
            this.dataimg.positiony = res.rspdata.positiony;
            this.dataimg.bgheight = res.rspdata.bgheight;
            this.dataimg.bgwidth = res.rspdata.bgwidth;
            this.dataimg.slideheight = res.rspdata.slideheight;
            this.dataimg.slidewidth = res.rspdata.slidewidth;
            this.ImageInitloading = false;
            resolve(true);
          })
          .catch((res) => {
            resolve(false);
          });
      });
    },

    Bloginfo() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          if (this.dataimg.visible) {
            return;
          }

          this.ImageInit().then((res) => {
            this.dataimg.visible = true;
            // 重新计算
            this.$nextTick(() => {
              this.$refs.dcPopover.$refs.dcPopover.updatePopper(); // 注意主要是这里
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.pc-all-dia-tit {
  box-sizing: border-box;
  text-align: center;
  font-weight: 500;
  border-radius: 4px;
  font-size: 24px;
  span {
    font-size: 12px;
    margin: auto;
  }
}

.pc-all-dia-tit-fu {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
}
.pc-all-dia-tit-logo {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  border-radius: 4px;
  font-size: 24px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

