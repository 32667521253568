<template>
  <div  class="header-search">
    <!-- <i class="iconfont iconsearch" @click.stop="click"></i> -->
       <img src="@/assets/搜索.svg"  @click.stop="click" alt="" />
    <input
      ref="searchInput"
      :class="{ show: show }"
      v-model="searchValue"
      type="text"
      @click.stop=""
      @keyup.enter="search"
    />

    <div>
      <ul v-if="Listshow" v-loading="loading" class="ulclass">
        <li
          v-for="(item, key) in options"
          @mousedown="mousedown(item)"
          :key="key + 'opt'"
        >
          <span>{{ item.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { GetSelectBlogTitle } from "../api/Resources";
export default {
  name: "header-search",
  data() {
    return {
      searchValue: "",
      show: false,
      Listshow: false,
      loading: false,
      options: []
    };
  },
  watch: {
    show(value) {
      if (value) {
        document.body.addEventListener("click", this.close);
      } else {
        document.body.removeEventListener("click", this.close);
      }
    },
    searchValue(newName, oldName) {
      //this.Listshow = newName == "" ? false : true;
      if (newName != "") {
        this.GetSelectBlogTitleInfo();
      } else {
        this.Listshow = false;
      }
    }
  },
  methods: {
    search() {
      this.$router.push({
        name: "search",
        params: { words: this.searchValue }
      });
      this.close();
    },
    click() {
      this.searchValue = "";
      this.show = !this.show;
      if (this.show) {
        this.$refs.searchInput && this.$refs.searchInput.focus();
      }
    },
    close() {
      this.$refs.searchInput && this.$refs.searchInput.blur();
      this.show = false;
      this.Listshow = false;
    },
    GetSelectBlogTitleInfo() {
      this.loading = true;
      var obj = new Object();
      obj.Title = this.searchValue;
      GetSelectBlogTitle(obj)
        .then(res => {
          if (res.rspdata.length == 0) {
            this.Listshow = false;
          } else {
            this.Listshow = true;
          }
          this.options = res.rspdata;
          this.loading = false;
        })
        .catch(erro => {
          this.loading = false;
        });
    },
    mousedown(item) {
      const icardUrl = this.$router.resolve({
        path: "/article/" + item.id
      });
      window.open(icardUrl.href, "_blank");
    }
  }
};
</script>

<style scoped lang="less">
.header-search {
  display: inline-block;
  position: relative;
  img {
    font-size: 18px;
    position: relative;
    top: 6px;
    width: 20px;
    height: 20px;
  }
  input {
    border: none;
    outline: none;
    overflow: hidden;
    background: transparent;
    height: 30px;
    width: 0;
    transition: 0.2s all;
    border-bottom: 1px solid #e4e7ed;
    &.show {
      width: 300px;
      margin-left: 10px;
    }
    &:focus {
      border-bottom: 1px solid red;
    }
  }

  .ulclass {
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
    list-style: none;
    padding: 6px 0;
    box-sizing: border-box;
    border: 1px solid #e4e7ed;
    border-radius: 20px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin: 8px 0;
    &:hover {
      border: 1 solid #409eff;
    }
    li {
      font-size: 14px;
      padding: 0 20px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      color: #606266;
      height: 34px;
      line-height: 34px;
      border-radius: 20px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background-color: #f5f7fa;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        white-space: nowrap;
        width: 300px;
        height: 24px;
        display: block;
      }
    }
  }
  .ulclass::after {
    width: 0;
    height: 0;
    border: 8px solid;
    border-color: transparent transparent #ebeef5;
    position: absolute;
    left: 35px;
    top: 21px;
    content: "";
  }
}
</style>
