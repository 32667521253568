<template>
  <div id="layout-header" :class="{ fixed: false, hidden: false }" @click.stop="mobileShow = false">
    <div class="site-logo">
      <router-link to="/">
        <img src="@/assets/logo.svg" alt />
      </router-link>
    </div>

    <div class="menus-btn" @click.stop="mobileShow = !mobileShow">Menus</div>

    <div
      class="site-menus"
      :class="{ mobileShow: mobileShow }"
      @click.stop="mobileShow = !mobileShow"
    >
      <div class="menu-item header-search">
        <header-search />
      </div>

      <div class="menu-item">
        <router-link to="/">首页</router-link>
      </div>

      <div class="menu-item hasChild">
        <a href="#">文章</a>

        <div class="childMenu" v-if="category.length">
          <div class="sub-menu" v-for="item in category" :key="item.classname">
            <router-link
              :to="{
                path: '/category/' + item.classname,
                query: { eq: item.id },
              }"
            >{{ item.classname }}</router-link>
          </div>
        </div>
      </div>

      <div class="menu-item">
        <router-link to="/about">关于</router-link>
      </div>

      <div v-if="!islogin" class="menu-item hasChild">
        <span style="cursor: pointer; font-weight: 500" @click="logininfo" slot="reference">登录</span>
        <!-- <el-popover
          placement="top"
          popper-class="pop-div-my"
          width="400"
          trigger="hover"
        >
          <el-row>
            <el-row type="flex" style="padding: 0 auto" justify="center">
              <el-col :span="23">
                <el-divider content-position="center">
                  <p style="font-size: 16px; color: #ff6d6d">
                    登录享受更多功能
                  </p>
                </el-divider>
              </el-col>
            </el-row>

            <el-row
              type="flex"
              :gutter="20"
              style="margin-top: 10px"
              justify="space-around"
            >
              <el-col :span="7" v-for="item in loginDataImg" :key="item.id">
                <el-row>
                  <el-image
                    style="
                      width: 80px;
                      height: 80px;
                      border-radius: 50%;
                      overflow: hidden;
                    "
                    lazy
                    :src="item.url"
                  >
                  </el-image>
                </el-row>

                <el-row style="margin-top: 10px">
                  <p style="margin-left: 10px; font-weight: 500">
                    {{ item.value }}
                  </p>
                </el-row>
              </el-col>
            </el-row>

            <el-row
              type="flex"
              style="padding: 10px 80px; margin-top: 10px"
              justify="center"
            >
              <el-col>
                <el-divider content-position="center">
                  <el-button @click="logininfo" type="danger">
                    <span style="font-size: 16px; font-weight: 500"
                      >登录</span
                    ></el-button
                  >
                </el-divider>
              </el-col>
            </el-row>
          </el-row>

         
        </el-popover>-->
      </div>

      <!-- <div class="menu-item">
        <router-link to="/File">资料</router-link>
      </div> --> 
      <div v-if="islogin" class="menu-item">
        <el-dropdown class="avatar-container" trigger="hover">
          <el-badge v-if="User.count > 0" :value="User.count" class="badgeitem">
            <div class="avatar-wrapper">
              <img :src="User.avarct" class="user-avatar" :title="User.name" />
            </div>
          </el-badge>
          <div v-else class="avatar-wrapper">
            <img :src="User.avarct" class="user-avatar" :title="User.name" />
          </div>

          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <router-link target="_blank" to="/Personal">
              <el-dropdown-item icon="el-icon-circle-check">个人主页</el-dropdown-item>
            </router-link>

            <el-dropdown-item icon="el-icon-circle-check">消息管理</el-dropdown-item>

            <el-dropdown-item @click.native="logout" icon="el-icon-circle-check">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-dialog
      title="登陆"
      :show-close="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      custom-class="el-dialog-radius"
      :modal="true"
      :modal-append-to-body="false"
      :visible.sync="$store.state.Popup"
      width="30%"
    >
      <login @fatherMethod="fatherMethod" ref="login"></login>

      <span slot="footer" class="dialog-footer">
        <el-button @click="$store.state.Popup = false">取 消</el-button>
        <el-button type="danger" @click="logogitee">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
var preD = function(e) {
  e.preventDefault();
};

import HeaderSearch from "@/components/header-search";

import login from "@/components/login";

import { Setguid } from "../../../src/utils/auth";

import { ClassModelSelect } from "../../api/Resources";

export default {
  name: "layout-header",

  components: { HeaderSearch, login },

  data() {
    return {
      lastScrollTop: 0,

      fixed: false,

      hidden: false,

      category: [],

      mobileShow: false,

      loginDataImg: [
        {
          url: "",

          value: "评论功能"
        },
        {
          url: "",

          value: "收藏功能"
        },
        {
          url: "",

          value: "同步功能"
        }
      ]
    };
  },
  mounted() {
    //window.addEventListener("scroll", this.watchScroll);

    this.fetchCategory();
  },

  beforeDestroy() {
    //window.removeEventListener("scroll", this.watchScroll);
  },
  methods: {
    logogitee() {
      Setguid(window.location.href);

      let url =
        "https://gitee.com/oauth/authorize?client_id=c724642fec67294d1caa24ee516eb4d115201c0ec3dcfbe87ee1da4a354e8b4d&redirect_uri=https://api.clound.ltd/api/Login/redirect&response_type=code&&scope=user_info";
      let a = document.createElement("a");
      a.setAttribute("href", url);
      document.body.appendChild(a);
      a.click();
    },
    /**
     * 是否显示头部
     */
    watchScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      switch (true) {
        case scrollTop === 0:
          this.fixed = true;
          this.hidden = false;
          break;

        case scrollTop >= this.lastScrollTop:
          this.fixed = false;
          this.hidden = true;
          break;

        default:
          this.fixed = true;
          this.hidden = false;
          break;
      }

      this.$store.dispatch("SET_hidden", this.hidden);

      this.$store.dispatch("SET_lastScrollTop", this.scrollTop);

      this.$store.dispatch("SET_fixed", this.fixed);

      this.lastScrollTop = scrollTop;
    },

    /**
     * 文章分类全部名称
     */
    fetchCategory() {
      this.$store.dispatch("ClassModel").then(res => {
        this.category = res;
      });
    },

    logininfo() {
      this.$store.state.Popup = true;
    },

    logout() {
      this.$store.dispatch("logout").then(res => {
        this.$notify({
          title: "成功",
          message: "恭喜你，退出成功",
          type: "success"
        });

        // setTimeout(() => {
        //   this.$router.go(0);
        // }, 1500);

        // this.$forceUpdate();

        //this.$router.go(0)
      });
    },

    /**
     * 登录成功回调刷新界面
     */
    fatherMethod(data) {
      this.$notify({ title: "成功", message: "欢迎回来", type: "success" });

      setTimeout(() => {
        this.$router.go(0);
      }, 1500);
    },

    handleClose() {}
  },

  computed: {
    islogin() {
      return this.$store.getters.islogin;
    },
    User() {
      return this.$store.getters.User;
    },
    dialogVisible() {
      return this.$store.state.Popup;
    }
  },

  watch: {
    // 监听data中弹层状态
    dialogVisible(val) {
      if (val) {
        document.body.style.overflow = "hidden";

        document.addEventListener("touchmove", preD, { passive: false }); // 禁止页面滑动
      } else {
        document.body.style.overflow = ""; // 出现滚动条

        document.removeEventListener("touchmove", preD, { passive: false });
      }
    }
  }
};
</script>

<style lang="scss">
.el-popover.pop-div-my {
  position: absolute;
  background: #fff;
  min-width: 150px;
  padding: 12px;
  line-height: 1.4;
  text-align: center;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.el-dialog__wrapper {
  z-index: 90000 !important;
}

.el-dialog.el-dialog-radius {
  position: relative;
  margin: 0 auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 10px;
  width: 50%;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
</style>


</style>

<style scoped lang="less">
#layout-header {
  margin: 0;
  width: 100%;
  z-index: 301;
  background: #fff;
  position: fixed;
  border-bottom: 0;
  min-width: 1060px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  top: 0;
  height: 80px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all linear;
  -o-transition: 0.3s all ease;
  -ms-transition: 0.3s all ease;
  &.hidden {
    top: -100px;
  }
  &.fixed {
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}

.site-logo {
  text-align: center;

  margin-left: 40px;

  img {
    width: 150px;
    height: 150px;
  }
}

.badgeitem {
  text-align: center;

  margin: 0 auto;
}

.avatar-container {
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  .avatar-wrapper {
    margin-top: 0px;
    position: relative;

    .user-avatar {
      cursor: pointer;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-top: 0px;
    }
  }
}
.menus-btn {
  display: none;
  visibility: hidden;
}
.site-menus {
  display: flex;
  align-items: center;

  .menu-item {
    min-width: 60px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: relative;
    a {
      padding: 12px 10px;
      color: #545454;
      font-weight: 500;
      font-size: 16px;
      &:hover {
        color: #ff6d6d;
      }
    }
    &:not(:last-child) {
      margin-right: 15px;
    }
    &.hasChild:hover .childMenu {
      opacity: 1;
      visibility: visible;
      transform: translateY(-5px);
    }

    span:hover {
      color: #ff6d6d;
    }
  }
  .childMenu {
    width: 130px;
    background-color: #fdfdfd;
    border-radius: 3px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 55px;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: 0.7s all ease;
    -webkit-transition: 0.6s all ease;
    -moz-transition: 0.6s all linear;
    -o-transition: 0.6s all ease;
    -ms-transition: 0.6s all ease;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 8px solid white;
      top: -8px;
      left: 20px;
    }
    &:before {
      top: -9px;
      border-bottom: 8px solid #ddd;
    }
    .sub-menu {
      height: 40px;
      line-height: 40px;
      position: relative;
      &:not(:last-child):after {
        /*position: absolute;*/
        content: "";
        width: 50%;
        height: 1px;
        color: #ff6d6d;
        bottom: 0;
        left: 25%;
        z-index: 99;
      }
    }
  }
}
@media (max-width: 960px) {
  #layout-header {
    padding: 0 20px;
  }
}
@media (max-width: 600px) {
  #layout-header {
    padding: 0 10px;
  }
  .menus-btn {
    display: block;
    visibility: visible;
  }
  .site-menus {
    position: absolute;
    display: none;
    visibility: hidden;
    background-color: #f9f9f9;
    width: 100%;
    left: 0;
    top: 80px;
    z-index: -9;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .menu-item {
      position: relative;
      height: unset;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
    .childMenu {
      position: relative;
      width: 100%;
      top: 0;
      background-color: #f3f3f3;
      opacity: 1;
      visibility: visible;
      border: none;
      box-shadow: none;
      &:before,
      &:after {
        content: "";
        position: relative;
        width: 0;
        height: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
      }
    }
  }
  .site-menus.mobileShow {
    display: inline-block;
    visibility: visible;
    z-index: 99;
  }
}
</style>
