import * as signalR from "@aspnet/signalr";
import { getToken } from '../../src/utils/auth' // get token from cookie
import store from '@/store'
import { Notification } from 'element-ui';
let signal = "";



/**
 * 获取 signal 对象
 * @returns 
 */
export function Getsignalr() {
    return signal
}



/**
 * 启动 signalR 连接
 * @returns 
 */
export async function start(hasToken) {
    try {
        signal = new signalR.HubConnectionBuilder()
            .withUrl("https://signalr.clound.ltd/MyHub?Authorization=" + hasToken, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Error)  // 日志等级
            .build();
        signal.keepAliveIntervalInMilliseconds = 12e4;
        signal.serverTimeoutInMilliseconds = 24e4;
        await signal.start()
        console.log("signal连接成功");
        store.dispatch('SET_signal', signal); //写入signal
        signal.off('Message');
        signal.off('Replynotice');
        signal.on('Message', res => {
            Notification({
                title: "点赞通知",
                dangerouslyUseHTMLString: true,
                message: "你有一个新的消息",
                duration: 4500,
                type: "success",
                data: res,
                onClick() {
                    store.dispatch("SET_MessageIsclike", false);
                    store.dispatch("SET_MessageIsclike", true);
                    store.dispatch("SET_MessageData", this.data);
                },
                position: "top-left"
            });
            console.log("Message♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥", res);
            console.log("Message♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥", JSON.stringify(res));
        })
        let t1 = setInterval(() => {
            const hasToken = getToken()//获取当前token 
            if (!hasToken) {
                clearInterval(t1);
                return
            }
            signal.invoke('Test');  //传递参数列表
            console.log("发送心电包成功")
        }, 5000);
        signal.on('Replynotice', res => {
            Notification({
                title: "回复通知",
                dangerouslyUseHTMLString: true,
                message: "左上角弹出的消息",
                duration: 4500,
                type: "success",
                data: res,
                onClick() {
                    store.dispatch("SET_ReplynoticeIsclike", false); 
                    store.dispatch("SET_ReplynoticeIsclike", true);
                    store.dispatch("SET_ReplynoticeData", this.data);
                },
                position: "top-left"
            });
            console.log("Replynotice♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥", res);
            console.log("Replynotice♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥", JSON.stringify(res));
        })
        signal.onclose(async () => {
            const hasToken = getToken()//获取当前token 
            if (hasToken) {
                console.log("异常了,token还在,重新调用")
                setTimeout(async () => await start(hasToken), 5000)
            }
            else {
                console.log("token不在无需调用")
            }
        })
        console.log('开启signalrc成功')
        return signal;
    } catch (err) {
        if (hasToken) {
            await start(hasToken);
        }
    }
}



/**
 * 退出 
 */
export async function stop() {

    if (signal == "") {
        return;
    }
    await signal.stop()
    console.log("关闭signalrc成功")
}


// //退出事件
// signal.onclose(async () => {
//     let hasToken = getToken();
//     if (hasToken) {
//         await start(hasToken);
//     }
// })
