<template>
  <div id="layout-footer">
      <div class="footer-item">
        <div>本站已苟活 {{ runTimeInterval }}</div>
        <div>
          版权 © 2020 
          <a target="_blank" class="out-link" href="https://beian.miit.gov.cn/"
            >赣ICP备2020010445号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionTitle from "@/components/section-title";

import {
  GetStreamModelAsync,
  WebsiteInfo,
  GetSocialsModelAsync,
  GetBlogAboutModel,
} from "../../api/Resources"; //获取接口对象

export default {
  name: "layout-footer",

  data() {
    return {
      socials: [],

      streamModel: [],
    };
  },
  components: {
    sectionTitle,
  },
  computed: {
    runTimeInterval() {
      return this.$store.state.runTimeInterval;
    },
  },
  methods: {
    getSocial() {
      // GetSocialsModelAsync().then(res=>{
      //   this.socials = res.rspdata;
      // }).catch(err => {this.socials=[]; })
    },

    /**
     * 前台调用获取资源文件
     */
    GetStreamModelAsync() {
      GetStreamModelAsync()
        .then((res) => {
          this.streamModel = res.rspdata; //获取到资源文件信息
        })
        .catch((err) => {
          this.socials = [];
        });
    },
  },
  created() {
    // this.getSocial();

    this.$store.dispatch("initComputeTime");

    // this.GetStreamModelAsync();
  },
};
</script>

<style scoped lang="less">
#layout-footer {
  padding: 2%;
  border-top: 1px solid #f7f7f7;
  font-size: 13px;
  color: #9c9c9c;
  text-align: center;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  a.out-link:hover {
    color: #ff6d6d;
  }
  .footer-main {
    width: 100%;
    margin: 0 auto;
    display: flex;
    text-align: center;
    justify-content: space-around;
    align-items: flex-start;
    .footer-item {
    
      flex: 1;
      & > div:not(:last-child) {
        margin-bottom: 10px;
      }
      i {
        margin-right: 10px;
      }
    }
  }
  .copyright {
    text-align: center;
  }
}
/*****/
@media (max-width: 1100px) {
  #layout-footer {
    .footer-main .footer-item:nth-child(3) {
      flex: 2;
    }
  }
}
@media (max-width: 600px) {
  #layout-footer {
    .footer-main {
      display: block;
      .footer-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        & > div {
          margin-bottom: 10px;
        }
      }
    }
  }
}
/*****/
</style>