// src/utils/highlight.js 文件路径，纯属自定义

// highlight.js  代码高亮指令
import Hljs from 'highlight.js';
import 'highlight.js/styles/docco.css'; // 代码高亮风格，选择更多风格需导入 node_modules/hightlight.js/styles/ 目录下其它css文件

//import 'highlight.js/styles/atom-one-dark.css';

let Highlight = {};
// 自定义插件
Highlight.install = function (Vue) {

    // 自定义指令 v-highlight
    Vue.directive('highlight', {
        // 被绑定元素插入父节点时调用
        inserted: function (el) {
            let blocks = el.querySelectorAll('pre code');
            blocks.forEach((block) => { // 在forEach中做一些操作
               
                Hljs.highlightBlock(block)
            })
        },
        // 指令所在组件的 VNode 及其子 VNode 全部更新后调用
        componentUpdated: function (el) {
            let blocks = el.querySelectorAll('pre code');
            blocks.forEach((block) => { // 在forEach中做一些操作
      
             
                Hljs.highlightBlock(block)
            })
        }
        
    })
};

export default Highlight;