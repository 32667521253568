<template>
  <el-popover
    ref="dcPopover"
    placement="top"
    width="300"
    v-model:visible="Data.visible"
    trigger="manual"
  >
    <div v-loading="ImageInitloading" style="text-align: right; margin: 0">
      <span @click="ImageInit" style="font-size:25px;cursor:pointer;"
        ><i class="el-icon-refresh"
      /></span>
      <span @click="Data.visible = false" style="font-size:25px;cursor:pointer;"
        ><i class="el-icon-close"
      /></span>
    </div>
    <div class="slider-panel" :style="{ width: width + 'px' }">
      <img ref="backgroudImg" :src="Data.backgroundimg" />
      <img
        ref="slideImg"
        class="cover"
        :src="Data.slideimg"
        :style="slideImgStyle"
      />

      <el-slider
        v-model="value3"
        @input="input"
        @change="change"
        :style="{ width: Data.bgwidth + 'px' }"
        :max="234"
        :show-tooltip="false"
      ></el-slider>
    </div>

    <slot name="ence" slot="reference"></slot>
  </el-popover>
</template>

<script>
export default {
  name: "slider",
  props: ["width", "Data", "ImageInitloading"],
  computed: {
    slideImgStyle() {
      var left = `${(this.Data.positionx / 300) * 100}%`;
      var top = `${this.Data.positiony}px`;
      return { top: top, left: left };
    }
  },
  data() {
    return {
      value3: 0
    };
  },
  methods: {
    input(data) {
      this.$emit("childinput", data);
    },
    change(data) {
      this.$emit("childchange", data);
    },
    ImageInit() {
      this.$emit("ImageInit");
    }
  }
};
</script>

<style scoped>
.slider-panel {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.cover {
  position: absolute;
}

.register {
  float: right;

  color: rgb(24, 144, 255);

  font-size: 16px;
}
</style>
